/* Component Dependencies */
var linksBlockTemplate = require('templates/linksBlock.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({
  type: 'linksBlock',
  template: {
    'linksBlock': linksBlockTemplate
  },

  bindEvents: function () {
    var _self = this;
		$(window).on('load', _self.onPageLoad.bind(_self));
  },

  onPageLoad: function() {
    var _self = this;
    if($(window).width() >= 769) { 
      _self.$el.find('.l-accordion-heading').attr("tabindex" , "-1");
    } else {
      _self.$el.find('.l-accordion-heading').removeAttr("tabindex");
    }
  }
});
